<template>
    <div class="textaPendulum">
        <div class="ml-20 mt-20">虚拟号绑定</div>
        <div class="mt-60">
            
            <el-form ref="upform" size="small" :model="upform" label-width="120px" :rules="rules">
                <el-form-item label="企业ID"  v-if="userType" prop="uid">
                <el-select v-model="upform.uid" placeholder="请选择"  clearable filterable class="form-item-w-400">
                  <el-option v-for="(item, index) in user_list" :key="index" :label="item.nickname" :value="item.uid">
                    <div class="flex-nowrap-space-between">
                      <div :class="item.status == 1?'no-red':''">
                        <span>{{ item.nickname }}</span>
                        <span class="ml-5">(Id:{{ item.uid }})</span>
                      </div>
                      <div v-if="item.status == 1" class="no-red">禁</div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
                <el-form-item label="主叫号码：" prop="telA">
                    <el-input v-model="upform.telA" class="form-item-w-400" placeholder="请输入主叫号码" clearable />
                </el-form-item>
                <el-form-item label="小号号码：" prop="telX">
                    <el-input v-model="upform.telX" class="form-item-w-400" placeholder="请输入小号号码" clearable />
                </el-form-item>
                <el-form-item label="">
                    <el-button size="small" plain class="yes-748bfd-bg" @click="upFormData('upform')">绑定</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import ctcc from '@/api/open/ctcc'
import interfacets from "@/api/open/privacy/interface";
import number from "@/api/open/privacy/number";
import {virtualBinding} from '@/api/web/virtualNumber'
import { getUserList } from '../../api/system/user';
import userts from '@/api/web/user'
export default {
    components: {

    },
    data () {
        return {
            user_list: [], // 用户的集合
            upform:{
                telX:'',
                telA:'',
                uid:'',
            },
             // uid:this.$store.state.user.uid
            userType:this.$store.state.userType=='system'?true:false  ,
            rules:{
                telA:[
                { required: true, message: '请输入主叫号码', trigger: 'change' },
                    { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号', trigger: 'change' }
                ],
                telX:[
                    { required: true, message: '请输入小号号码', trigger: 'change' },
                    { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号', trigger: 'change' }
                ],
                uid:[
                    {
                    required: true,
                    message: '请选择企业ID',
                    trigger: 'change',
                    },
                ],
            }
        }
    },
    created() {
        
    },
    watch: {

    },
    mounted() {
        
       this.getUserList()
    },
    methods: {
        upFormData(formName) {
           
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    virtualBinding({
                        ...this.upform,
                    }).then(res => {
                        if(res.code == 200){
                            this.$notify({
                                title: '成功',
                                message: '绑定成功！',
                                type: 'success',
                            })
                            console.log(res,'=============');
                            this.upform.telX='',
                            this.upform.telA=''
                         
                        }
                       
                    })
                } else {
                    
                    console.log('error submit!!')
                    return false
                }
            })
        },
          // 获取用户列表
    getUserList() {
      userts.queryList({}).then((res) => {
        this.user_list = res.data
        console.log(res,'---------------------');
      })
    },
        
    }
}
</script>
<style lang="scss" scoped>
    .form-item-w-400{
        width: 400px;
    }
    .form-item-w-200{
        width: 200px;
    }
</style>