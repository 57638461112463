// 用户管理
import { Request } from '@/http/request'

// 用户菜单页
export function getUserMenuTree() {
  return Request.axiosInstance.post('/system/admin-user/user-menu')
}
// 用户信息
export function getUser(parameter: any) {
  return Request.axiosInstance.post('/system/admin-user/get', parameter)
}
// 用户列表
export function getUserList(parameter: any) {
  return Request.axiosInstance.post('/system/admin-user/list', parameter )
}
// 用户列表
export function addUser(parameter: any) {
  return Request.axiosInstance.post('/system/admin-user/add', parameter )
}
// 删除用户
export function deleteUser(parameter: any) {
  return Request.axiosInstance.post('/system/admin-user/delete', parameter )
}
// 修改用户资料
export function editUser(parameter: any) {
  return Request.axiosInstance.post('/system/admin-user/edit', parameter )
}
// 重置用户密码 
export function resetPassword(parameter: any) {
  return Request.axiosInstance.post('/system/admin-user/reset-password', parameter )
}
// 修改用户密码 
export function updatePassword(parameter: any) {
  return Request.axiosInstance.post('/system/admin-user/update-password', parameter )
}
// 当前登录用户信息
export function getInfo() {
  return Request.axiosInstance.post('/system/admin-user/info')
}

export default { getUserMenuTree, getUser, getUserList, addUser, deleteUser, editUser, getInfo, resetPassword, updatePassword }
