// 隐私号--接口管理
import { Request } from '@/http/request'

// 查询接口集合
export function seeApiList(){
  return Request.axiosInstance.post('open/interface/query-name')
}

// 接口数据列表
export function apiRunList(parameter: any) {
  return Request.axiosInstance.post('/open/interface/list', parameter)
}

// 接口设置添加
export function addInterface(parameter: any){
  return Request.axiosInstance.post('/open/interface/add', parameter)
}

// 接口设置修改
export function editInterface(parameter: any){
  return Request.axiosInstance.post('/open/interface/edit', parameter)
}

// 接口设置删除
export function deleteInterface(parameter: any){
  return Request.axiosInstance.post('/open/interface/delete', parameter)
}

export function getInterface(parameter: any){
  return Request.axiosInstance.post('/open/interface/get-interface', parameter)
}
// 接口调试
export function debugging(parameter: any){
  return Request.axiosInstance.post('/open/interface/debugging', parameter)
}
// 接口熔断
export function isFuse(parameter: any){
  return Request.axiosInstance.post('/open/interface/is-fuse', parameter)
}
// 复制
export function copy(parameter: any){
  return Request.axiosInstance.post('/open/interface/copy', parameter)
}
export function activatephone(parameter: any) {
  return Request.axiosInstance.post(
      "/open/number-activation/shuang-hu-activate-phone",
      parameter
  );
}
// 查询通黑名单拦截类型
export function getOption(parameter: any){
  return Request.axiosInstance.post('/open/black-user-option/get-option', parameter)
}
//配置通话黑名单拦截类型
export function setOption(parameter: any){
  return Request.axiosInstance.post('/open/black-user-option/set-option', parameter)
}
export default {setOption,getOption,seeApiList,apiRunList,addInterface,editInterface,deleteInterface,getInterface, debugging,isFuse,copy,activatephone } 