// 用户管理
import { Request } from '@/http/request'

// 用户菜单页
export function getUserMenuTree(parameter: any) {
  return Request.axiosInstance.post('/web/user/user-menu',parameter)
}
// 当前登录用户信息
export function getInfo(parameter: any) {
  return Request.axiosInstance.post('/web/user/info',parameter)
}
// 注册信息完善
export function perfection (parameter: any) {
  return Request.axiosInstance.post('/web/user/information-perfection', parameter)
}
// 修改资料
export function editData(parameter: any) {
  return Request.axiosInstance.post('/web/user/edit',parameter)
}
// 查看详情
export function getData(parameter: any) {
  return Request.axiosInstance.post('/web/user/get',parameter)
}
// 修改密码 
export function updatePassword(parameter: any) {
  return Request.axiosInstance.post('/web/user/update-password',parameter)
}
// 初始化密码 
export function initPassword(parameter: any) {
  return Request.axiosInstance.post('/web/user/init-password',parameter)
}
// 查询用户列表
export function queryList(parameter: any) {
  return Request.axiosInstance.post('/web/user/query', parameter)
}
// 修改密码
export function resetPassword(parameter: any) {
  return Request.axiosInstance.post('/web/user/reset-password', parameter)
}
// 修改密码
export function loginwebuser(parameter: any) {
  return Request.axiosInstance.post('/web/auth/system-login-web-user', parameter)
}

// 免登陆token
export function freeToken(parameter: any) {
  return Request.axiosInstance.post('/web/auth/free-login', parameter)
}
// 渠道用户查看号码
export function getPhoneByUid(parameter: any) {
  return Request.axiosInstance.post('/web/user/get-phone-by-uid', parameter)
}

// 账户修改
export function editInfo(parameter: any) {
  return Request.axiosInstance.post('/system/admin-user/edit-info', parameter)
}
// 用户价格备注列表查询
export function userPricesRemarkList(parameter: any) {
  return Request.axiosInstance.post('/open/user-prices-remark/list', parameter)
}
// 用户价格备注添加
export function userPricesRemarkAdd(parameter: any) {
  return Request.axiosInstance.post('/open/user-prices-remark/add', parameter)
}

export default {userPricesRemarkAdd,userPricesRemarkList,editInfo,freeToken, getUserMenuTree, getInfo, editData, updatePassword, initPassword, queryList, getData, perfection, resetPassword, loginwebuser,getPhoneByUid }