// 隐私号--号码池
import { Request } from "@/http/request";

// 数据列表
export function getList(parameter: any) {
  return Request.axiosInstance.post("/open/number/list", parameter);
}

// 数据添加
export function addNumber(parameter: any) {
  return Request.axiosInstance.post("/open/number/add", parameter);
}

// 数据修改
export function editNumber(parameter: any) {
  return Request.axiosInstance.post("/open/number/edit", parameter);
}

// 数据删除
export function deleteNumber(parameter: any) {
  return Request.axiosInstance.post("/open/number/delete", parameter);
}

// 号码导入
export function importNumber(parameter: any) {
  return Request.axiosInstance.post("/open/number/import", parameter);
}
export function auditPLus(parameter: any) {
  return Request.axiosInstance.post("/open/user-number/number-recover-audit-plus", parameter);
}

// 号码导出
export function exportNumber(parameter: any) {
  return Request.axiosInstance.post("/open/number/export", parameter);
}

// 查询可分配中间号数量
export function countNumber(parameter: any) {
  return Request.axiosInstance.post(
    "/open/number/available-number-count",
    parameter
  );
}

// 查询省市中间号集合
export function cityNumber(parameter: any) {
  return Request.axiosInstance.post("/open/number/number-count", parameter);
}
// 批量修改
export function numberBatchEdit(parameter: any) {
  return Request.axiosInstance.post("/open/number/batch-edit", parameter);
}
// 初次导入
export function firstImport(parameter: any) {
  return Request.axiosInstance.post("/open/number/first-import", parameter);
}

// 确认导入
export function confirmImport(parameter: any) {
  return Request.axiosInstance.post("/open/number/confirm-import", parameter);
}

// 确认导入
export function available(parameter: any) {
  return Request.axiosInstance.post(
    "/open/number/available-number-count",
    parameter
  );
}

export function numberCount(parameter: any) {
  return Request.axiosInstance.post("/open/number/number-count", parameter);
}

// 小号所属用户
export function numberUser(parameter: any) {
  return Request.axiosInstance.post("/open/number/number-user", parameter);
}

// 小号回收
export function recycle(parameter: any) {
  return Request.axiosInstance.post("/open/number/recycle", parameter);
}

// 小号退网
export function withdrawNetwork(parameter: any) {
  return Request.axiosInstance.post("/open/number/withdraw-network", parameter);
}
// 退网记录列表记录
export function recycleList(parameter: any) {
  return Request.axiosInstance.post("/open/number/recycle/list", parameter);
}
// 退网记录列表导出
export function recycleExport(parameter: any) {
  return Request.axiosInstance.post("/open/number/recycle/export", parameter);
}

// 用户可用号码查询
export function getNumberlist(parameter: any) {
  return Request.axiosInstance.post(
    "/open/user-number/get-number-list",
    parameter
  );
}
// 4号回拨用户可用号码查询
export function getHaoWaiNumberlist(parameter: any) {
  return Request.axiosInstance.post(
    "/open/user-number/get-hao-wai-number-list",
    parameter
  );
}
// 点慧回拨用户可用号码查询
export function getDianHuiNumberlist(parameter: any) {
  return Request.axiosInstance.post(
    "/open/user-number/get-dian-hui-number-list",
    parameter
  );
}
// 新疆回拨用户可用号码查询
export function getXinJiangNumberlist(parameter: any) {
  return Request.axiosInstance.post(
      "/open/user-number/get-xin-jiang-number-list",
      parameter
  );
}

export function editRecord(parameter: any) {
  return Request.axiosInstance.post(
      "/open/user-number/edit-record",
      parameter
  );
}

export function distribution(parameter: any) {
  return Request.axiosInstance.post(
      "/open/user-number/batch-distribution-plus",
      parameter
  );
}


export default {
  distribution,
  editRecord,
  numberCount,
  getList,
  addNumber,
  editNumber,
  deleteNumber,
  importNumber,
  exportNumber,
  countNumber,
  cityNumber,
  numberBatchEdit,
  firstImport,
  confirmImport,
  available,
  getNumberlist,
  getHaoWaiNumberlist,
  getDianHuiNumberlist,
  getXinJiangNumberlist,
  numberUser,
  recycle,
  withdrawNetwork,
  recycleList,
  recycleExport,
 auditPLus

};
